export interface PersonalInfo {
	nameSurname: string;
	contactNumber: string;
	idNumber: string;
	dob: string;
	fathersName: string;
	email: string;
	idType: string;
}

export enum PersonalInfoActionTypes {
	SAVE_PERSONAL_INFO = "@@personalInfo/SAVE",
	DELETE = "@@personalInfo/DELETE",
}

export type PersonalInfoState = {
	readonly nameSurname: string;
	readonly contactNumber: string;
	readonly idNumber: string;
	readonly dob: string;
	readonly fathersName: string;
	readonly email: string;
	readonly idType: string;
};
