import React from "react";

//  import { Dispatch } from "redux";
import { useDispatch } from "react-redux";

import PageWrapper from "App/components/PageWrapper";
import { useTranslation } from "react-i18next";
import TimeLine from "./TimeLine";
import "./style.scss";
import landingImageEn from "assets/images/campaign-en.jpg";
import landingImageTr from "assets/images/campaign-tr.jpg";
import Container from "App/components/Container";
import { Link, useLocation } from "react-router-dom";
import routes from "App/routes";

import { AddressInfoActionTypes } from "store/addressInfo/types";
import { PersonalInfoActionTypes } from "store/personalInfo/types";
import { PackageInfoActionTypes } from "store/packageInfo/types";
import { MsisdnActionTypes } from "store/msisdn/types";


const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

const LandingPage = () => {
	const { t } = useTranslation();
	function useQuery() {
		const { search } = useLocation();

		return React.useMemo(() => new URLSearchParams(search), [search]);
	}

	const isStepFailed = (step: number) => {
		return step === 3;
	};

	const dispatch = useDispatch();

	const [value, setValue] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	dispatch({
		type: AddressInfoActionTypes.DELETE,
	});
	dispatch({
		type: PersonalInfoActionTypes.DELETE,
	});
	dispatch({
		type: PackageInfoActionTypes.DELETE,
	});
	dispatch({
		type: MsisdnActionTypes.DELETE,
	});

	let query = useQuery();
	console.log("query ", query.get("code"))
	const queryCode = query.get("code")
	return (
		<PageWrapper>
			<Container className="Container--align-contents-middle">
				<div className="grid">
					<div className="grid__row">
						<div className="grid__column">
							<div className="Landing__card">
								<h2 className="Landing__cardHeader heading--center">
									{t("cardMNT.title")}
								</h2>
								<h2 className="Landing__cardText heading--center">
									{t("cardMNT.text")}
								</h2>
								<div className="Landing__buttons">
									<Link to={`${routes.mnt}${queryCode === null || queryCode === 'null' ? '' : `?code=${queryCode}`}`}>
										<button type="button" className="Landing__button">
											{t("cardMNT.buttonText")}
										</button>
									</Link>

								</div>
							</div>
						</div>
						<div className="grid__column">
							<div className="Landing__card">
								<h2 className="Landing__cardHeader heading--center">
									{t("cardNumara.title")}
								</h2>
								<h2 className="Landing__cardText heading--center">
									{t("cardNumara.text")}
								</h2>
								<div className="Landing__buttons">
									<Link to={`${routes.searchPhoneNumber}${queryCode === null || queryCode === 'null' ? '' : `?code=${queryCode}`}`}>
										<button type="button" className="Landing__button">
											{t("cardNumara.buttonText")}
										</button>
									</Link>

								</div>
							</div>
						</div>
						{/* <div className="grid__column">
							<img
								className="Landing__image"
								src={t("lang") == "tr" ? landingImageTr : landingImageEn}
								alt={t("title")}
							/>
						</div> */}

						<div className="grid__column">
							<div className="Landing__card">
								<h2 className="Landing__cardHeader heading--center">
									{t("cardFatura.title")}
								</h2>
								<h2 className="Landing__cardText heading--center">
									{t("cardFatura.text")}
								</h2>
								<div className="Landing__buttons">
									<Link to={`${routes.switch}${queryCode === null || queryCode === 'null' ? '' : `?code=${queryCode}`}`}>
										<button type="button" className="Landing__button">
											{t("cardFatura.buttonText")}
										</button>
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="grid_row">
						<div className="grid__column">
							<h2 className="Landing__heading heading heading--navy heading--center">
								{t("landing.title")}
							</h2>
							<TimeLine />
							{/* <Stepper alternativeLabel activeStep={2}
								connector={<StepConnector classes={{
									root: "Landing__stepperBorder"
								}} />}
							>
								{steps.map((label, index) => {
									const labelProps: {
										optional?: React.ReactNode;
										error?: boolean;
									} = {};
									if (isStepFailed(index)) {
										labelProps.optional = (
											<Typography variant="caption" color="error">
												Alert message
											</Typography>
										);
										labelProps.error = true;
									}

									return (
										<Step key={label}>
											<StepLabel StepIconComponent={IconCallyou} >{t("landing.callYou")}</StepLabel>
										</Step>
									);
								})}
							</Stepper> */}
							{/* <TimeLine className="Landing__timeline" /> */}
							{/* <div className="Landing__buttons">
								<Link to={`${routes.searchPhoneNumber}${queryCode === null || queryCode === 'null' ? '' : `?code=${queryCode}`}`}>
									<button type="button" className="Landing__button button">
										{t("landing.buttonNewNumber")}
									</button>
								</Link>
							</div> */}
						</div>
					</div>				
				</div>
			</Container>
		</PageWrapper>
	);
};

export default LandingPage;
