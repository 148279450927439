import Accordion from "App/components/Accordion";
import Container from "App/components/Container";
import PageWrapper from "App/components/PageWrapper";
import IconGlobe from "assets/svg/IconGlobe";
import IconPackage from "assets/svg/IconPackage";
import IconSMS from "assets/svg/IconSMS";
import IconTurkishLira from "assets/svg/IconTurkishLira";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./style.scss";
import IconSim from "assets/svg/IconSim";
import IconCellPhoneList from "assets/svg/IconCellPhoneList";
import api from "App/api";
import { cmsApiEndpoints } from "App/api/endpoints";
import RadioButton from "App/components/RadioButton";
import IconTime from "assets/svg/IconTime";
import IconCaretDown from "assets/svg/IconCaretDown";
import {
	tCurrency,
	tPeriodUnit,
	tPriceDescription,
} from "App/helpers/translators";
import {
	PackageInfoActionTypes,
	PackageInfoState,
} from "store/packageInfo/types";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import routes from "App/routes";
import { LoadingSpinnerActionTypes } from "store/loadingSpinner/types";
import Modal from "App/components/Modal";
import IconError from "assets/svg/IconError";
import { PersonalInfoState } from "store/personalInfo/types";
import { getAge } from "App/helpers/functions";
import ProcessBar from "App/components/ProcessBar";
import Card from "App/components/Card";
import { stringify } from "node:querystring";
import IconRadio from "assets/svg/IconRadio";
import IconPhone from "assets/svg/IconPhone";
import { MsisdnState } from "store/msisdn/types";

interface LocationState {
	from: {
		pathname: string;
	};
	prepaidOnly: boolean;
	hasPrev?: boolean;
	queryCode?: string;
}

const PackageInfoPage = () => {
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	const location = useLocation<LocationState>();

	const prepaidOnly = useMemo(() => location.state?.prepaidOnly, [location]);

	console.log("IS PREPAID ONLY ?", prepaidOnly);

	const [packages, setPackages] = useState<Array<any>>([]);
	const [groups, setGroups] = useState<Array<any>>([]);

	const [showGetPackagesFailedModal, setShowGetPackagesFailedModal] =
		useState(false);

	const personalInfo: PersonalInfoState = useSelector(
		(state: ApplicationState) => state.personalInfo,
		shallowEqual
	);

	const existMsisdn: MsisdnState = useSelector(
		(state: ApplicationState) => state.msisdn,
		shallowEqual
	);

	// TODO: THIS WILL BE ACTIVATED NEXT PHASE, FOR NOW POSTPAID IS DISABLED
	// const isPostpaidForbidden = useCallback(() => {
	// 	if (prepaidOnly) {
	// 		return true;
	// 	}
	// 	if (
	// 		personalInfo.nationality !== "245" &&
	// 		personalInfo.nationality !== "260"
	// 	) {
	// 		return true;
	// 	}
	// 	return false;
	// }, [prepaidOnly, personalInfo.nationality]);

	const isPostpaidForbidden = useCallback(() => {
		return true;
	}, []);

	const [payload, setPayload] = useState(isPostpaidForbidden() ? "1" : "0"); // payload -> postpaid = 1, prepaid = 0

	const selectedPackage: PackageInfoState = useSelector(
		(state: ApplicationState) => state.packageInfo,
		shallowEqual
	);

	const handlePackageTypeClick = useCallback(() => {
		dispatch({
			type: LoadingSpinnerActionTypes.SHOW_LOADING_SPINNER,
		});

		const apiEndpoint = cmsApiEndpoints.GetAllPackage;
		api
			.post(apiEndpoint, {
				payload: payload,
				segment: "Individual",
				lang: i18n.language,
			})
			.then((response) => {
				const data = response.data; 
				 
				 
				if (data.Result?.Packages) {
					let packages = data.Result.Packages.filter(
						(pkg: any) => (pkg.NewSubscriberAvailable === true
							&& pkg.SegmentType === 'Individual' && pkg.Payload === 'Postpaid')
					);


					console.log("existMsisdn.returnPage" + existMsisdn.returnPage);
					//Check nationality and filter packages accordingly
					if (existMsisdn.returnPage === routes.mnt) {
						packages = packages.filter(
							(pkg: any) =>
								pkg.State === "Offline"
								&& (pkg.PackageSubCategories.filter((category: any) => category.Title === "MNT Paketleri"))
						);
					}
					else {
						if (existMsisdn.returnPage === routes.switch) {
							packages = packages.filter(
								(pkg: any) =>
									pkg.State === "Online"
									&& pkg.PackageCategories.length > 0 
							);
							let filteredData:any=[];
							packages.forEach((element:any) => {
								element.PackageCategories.forEach((item:any) => {
									if (item.Title==="Faturalı Hatta Geçiş") {
										filteredData.push(element) 
									}
								});
							});
							packages=filteredData;
						}
						else {
							packages = packages.filter(
								(pkg: any) =>
									pkg.State === "Online"
									&& pkg.PackageCategories.length > 0
									&& (pkg.Benefits.filter((x: any) => x.ShowHomePage === true))
							);
							let filteredData:any=[];
							packages.forEach((element:any) => {
								element.PackageCategories.forEach((item:any) => {
									if (item.Title==="Faturalı") {
										filteredData.push(element) 
									}
								});
							}); 

							packages=filteredData; 

						}
					}


					//SİLME
					console.log(packages.map(
						(pkg: any) => {
							pkg.PurchaseOptions.sort((a: any, b: any) => a.Price - b.Price).slice(0, 1).map((priceOption: any) => (
								pkg.visibleText = priceOption.Price + " " + tCurrency(priceOption.Currency) +
								"/" + tPeriodUnit(priceOption.PeriodUnit)
							))
						}
					));

					setPackages(packages);

					let _groups: any = [];
					packages.forEach((pkg: any) => {
						if (
							_groups.find((group: any) => group.title === pkg.Group.Title) ===
							undefined
						) {
							_groups.push({
								title: pkg.Group.Title,
								count: packages.filter(
									(item: any) => item.Group.Title === pkg.Group.Title
								).length,
							});
						}
					});

					//console.log("Filter " + JSON.stringify(packages));
					setGroups(_groups);
				}
			})
			.catch((e) => {
				setShowGetPackagesFailedModal(true);
				// console.log(e);
			})
			.then(() => {
				dispatch({
					type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
				});
			});
	}, [dispatch, payload, i18n.language, personalInfo]);

	useEffect(() => {
		console.log("PAYLOAD:", payload);
	}, [payload, dispatch]);

	useEffect(() => {
		handlePackageTypeClick();
	}, [payload, dispatch, handlePackageTypeClick]);

	const handleSubmit = () => {
		dispatch({
			type: PackageInfoActionTypes.SAVE_PACKAGE_INFO,
			payload: {
				payload: payload,
			},
		});

		if (existMsisdn.returnPage === routes.mnt) {
			history.push({
				pathname: routes.personalInfo,
				state: {
					hasPrev: true,
					queryCode: queryCode,
					operation: true
				},
			});
		}
		else if (existMsisdn.returnPage === routes.switch) {
			history.push({
				pathname: routes.switchPersonalInfo,
				state: {
					hasPrev: true,
					queryCode: queryCode,
					operation: true
				},
			});
		}
		else {
			history.push({
				pathname: routes.personalInfo,
				state: {
					hasPrev: true,
					queryCode: queryCode,
				},
			})
		};
		// TODO: Launch Modal if no selected package in state
	};

	const hasPrev = useMemo(() => location.state?.hasPrev || false, [location]);
	const queryCode = useMemo(() => location.state?.queryCode || "", [location]);

	if (queryCode == null || queryCode == "null" || queryCode == "" || queryCode == undefined) {
		console.log("querycode empty ")
	} else {
		var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?code=' + queryCode;
		window.history.pushState({ path: newurl }, '', newurl);
	}

	if (hasPrev !== true) {
		return <Redirect to={routes.root} />;
	}

	interface BenefitsProps {
		items: any;
		filter: string;
	}

	const GetPackagesFailedModal = () => (
		<Modal show={showGetPackagesFailedModal}>
			<div className="Modal__content PackageInfo__modal">
				<IconError className="Modal__error-icon" />
				<h2 className="Modal__title">
					{t("packageInfo.getPackagesFailedTitle")}
				</h2>
				<p className="Modal__description">
					{t("packageInfo.getPackagesFailedText")}
				</p>
				<button
					type="button"
					className="button Modal__button"
					onClick={() => {
						setShowGetPackagesFailedModal(false);
						handlePackageTypeClick();
					}}
				>
					{t("packageInfo.tryAgain")}
				</button>
			</div>
		</Modal>
	);

	const Benefits: React.FC<BenefitsProps> = ({ items, filter }) => {
		const filteredBenefits = items.filter(
			(benefit: any) => benefit.BenefitType.Types === filter
		);

		if (filteredBenefits.length < 1) {
			return (
				<div className="Benefit">
					<div className="Benefit__value-group">
						<span className="Benefit__value">-</span>
					</div>
				</div>
			);
		}

		return filteredBenefits.map((benefit: any, index: number) => (
			<>			{
				benefit.ShowHomePage ? (
					<div className="Benefit" key={`${filter} - ${index}`}>

						<div className="Benefit__value-group">
							<span className="Benefit__value">{benefit.Value}</span>
							<span className="Benefit__unit">{benefit.Unit}</span>
						</div>
						<div className="Benefit__description">{benefit.Title}</div>
					</div>
				) : ""
			}</>


		));
	};

	const BenefitsTableRow: React.FC<any> = () => {
		return (
			<div className="Benefits__table-row">
				<div className="Benefits__table-heading Benefits__table-heading--no-border-bottom"></div>
				<div className="Benefits__table-heading">
					<IconPackage className="Benefits__table-heading__icon" />
					{t("benefit.titlePackage")}
					<IconCaretDown className="Benefits__table-heading__caret" />
				</div>
				<div className="Benefits__table-heading">
					<IconTime className="Benefits__table-heading__icon" />
					{t("benefit.titleVoice")}
					<IconCaretDown className="Benefits__table-heading__caret" />
				</div>
				<div className="Benefits__table-heading">
					<IconSMS className="Benefits__table-heading__icon" />
					{t("benefit.titleSMS")}
					<IconCaretDown className="Benefits__table-heading__caret" />
				</div>
				<div className="Benefits__table-heading">
					<IconGlobe className="Benefits__table-heading__icon" />
					{t("benefit.titleInternet")}
					<IconCaretDown className="Benefits__table-heading__caret" />
				</div>
				<div className="Benefits__table-heading">
					<IconTurkishLira className="Benefits__table-heading__icon" />
					{t("benefit.titlePrice")}
					<IconCaretDown className="Benefits__table-heading__caret" />
				</div>
			</div>
		);
	};

	const onPackageSelect = (selected: string, pkg: any) => {
		console.log("selected", JSON.stringify(pkg));
		dispatch({
			type: PackageInfoActionTypes.SAVE_PACKAGE_INFO,
			payload: {
				packageId: pkg.PurchaseOptions.sort((a: any, b: any) => a.Price - b.Price).slice(0, 1)[0].PackageId,
				name: pkg.Title,
				payload: payload,
				campId: pkg.camp_id ?? "0",
				price: pkg.visibleText
			},
		});
		handleSubmit();
	};

	return (
		<>
			<PageWrapper>
				<div className="grid">
					<div className="grid__rowProcess">
						<div className="grid__column grid__columnWitoutPadding">
							<ProcessBar fromPage={existMsisdn.returnPage} step={2} />
						</div>
					</div>
				</div>
				<Container className="Container--align-contents-middle">
					<h2 className="heading heading--navy heading--center heading--page-title">
						{t("packageInfo.title")}
					</h2>

					{!prepaidOnly ? (
						isPostpaidForbidden() ? (
							<div className="PackageInfo__smaller-container">
								{/* <p className="PackageInfo__unpaid-text">
									{t("packageInfo.prepaidOnlyText")}
									<br />
									<Trans i18nKey="packageInfo.gncMoreInfo">
										GNÇ Paketlerini incelemek için{" "}
										<a
											href="https://www.kktcell.com/gnc"
											rel="noreferrer"
											target="_blank"
											className="link link--blue link--underline"
										>
											tıklayınız
										</a>
										.
									</Trans>
									<br />
									<Trans i18nKey="packageInfo.askerMoreInfo">
										Asker Paketlerini incelemek için{" "}
										<a
											href="https://www.kktcell.com/faturasiz"
											rel="noreferrer"
											target="_blank"
											className="link link--blue link--underline"
										>
											tıklayınız
										</a>
										.
									</Trans>
								</p> */}
							</div>
						) : (
							<>
								<div className={`PayloadSelector`}>
									<button
										className={`PayloadSelector__button ${payload === "1" ? "active" : ""
											}`}
										onClick={() => {
											setPayload("1");
										}}
									>
										<IconSim className="PayloadSelector__icon" />
										<div className="PayloadSelector__text">
											{t("packageInfo.getPostpaid")}
										</div>
									</button>
									<button
										className={`PayloadSelector__button ${payload === "0" ? "active" : ""
											}`}
										onClick={() => {
											setPayload("0");
										}}
									>
										<IconCellPhoneList className="PayloadSelector__icon" />
										<div className="PayloadSelector__text">
											{t("packageInfo.getPrepaid")}
										</div>
									</button>
								</div>
								<p className="PackageInfo__unpaid-text">
									{t("packageInfo.suitableResultsBelow")}
								</p>
							</>
						)
					) : (
						<div className="PackageInfo__smaller-container">
							{/* <p className="PackageInfo__unpaid-text">
								{t("packageInfo.postpaidNotAllowed")}
								<br />
								<Trans i18nKey="packageInfo.gncMoreInfo">
									GNÇ Paketlerini incelemek için{" "}
									<a
										href="https://www.kktcell.com/gnc"
										rel="noreferrer"
										target="_blank"
										className="link link--blue link--underline"
									>
										tıklayınız
									</a>
									.
								</Trans>
								<br />
								<Trans i18nKey="packageInfo.askerMoreInfo">
									Asker Paketlerini incelemek için{" "}
									<a
										href="https://www.kktcell.com/faturasiz"
										rel="noreferrer"
										target="_blank"
										className="link link--blue link--underline"
									>
										tıklayınız
									</a>
									.
								</Trans>
							</p> */}
						</div>
					)}
					<div className="PackageInfo__packages">
						{payload === "0" ? (
							<>
								<div className="grid">
									<div className="grid__row">
										{packages.map((pkg: any, index: any) => {
											return (
												<div className="grid__column">
													<div className="PackageInfo__card">
														<h2 className="PackageInfo__cardHeader heading--center">
															{pkg.Title}
														</h2>

														<div className="PackageInfo__cardMain">
															<div className="grid__row">
																<div className="grid__column"></div>
																<div className="grid__column"></div>
																<div className="grid__column"></div>
															</div>

														</div>
														<div className="PackageInfo__cardFooter">
															<h2 className="ChooseNumber__description">
																{/* <RadioButton
																	key={pkg.Id}
																	selected={selectedPackage.packageId}
																	value={pkg.Id}
																	onChange={(selected: any) =>
																		onPackageSelect(selected, pkg)
																	}
																	className="Package Package--margin-bottom-20 RadioButton--checks-on-top"
																>
																	<div className="Package__title Package__title--navy">
																		{pkg.Title}

																		<div className="Package__title__description">
																			<p>{pkg.Description}</p>
																		</div>
																	</div>
																</RadioButton> */}
																<div className="PackageInfo__buttons">
																	<button
																		className="PackageInfo__submit PackageInfo__button"
																		// disabled={selectedPackage.packageId ? false : true}
																		onClick={() =>
																			onPackageSelect(pkg.Id, pkg)}
																	>
																		{t("packageInfo.butonText")}
																	</button>
																</div>
															</h2>

														</div>
													</div>
												</div>

											);
										})}	</div>

								</div>
							</>
						) : (
							<div className="grid">
								<div className="grid__rowU3">
									{packages.map((pkg: any, index: any) => {
										return (
											<div className="grid__column">
												<div className="PackageInfo__card">
													<h2 className="PackageInfo__cardHeader heading--center">
														{pkg.Title}
													</h2>
													<div className="PackageInfo__cardMain">
														<div className="grid__rowFDirC">
															<div className="grid__column">
																<IconRadio></IconRadio>
																<div className="Package__data Package__cell">
																	<Benefits items={pkg.Benefits} filter="Data" />
																</div>
															</div>
															<div className="grid__columnU4">
																<IconPhone></IconPhone>
																<div className="Package__voice Package__cell">
																	<Benefits items={pkg.Benefits} filter="Voice" />
																</div>
															</div>
														</div>
													</div>
													<div className="PackageInfo__cardFooter">
														<h2 className="ChooseNumber__description">
															<div className="PackageInfo__buttons">
																<div className="Package__prices Package__cell">
																	{pkg.PurchaseOptions.sort((a: any, b: any) => a.Price - b.Price).slice(0, 1).map((priceOption: any) => (
																		<div className="Price" key={priceOption.Id}>
																			<div className="Price__group">
																				<span className="Price__value">
																					{priceOption.Price}
																				</span>
																				<span className="Price__unit-per-period">
																					{tCurrency(priceOption.Currency)}/
																					{tPeriodUnit(priceOption.PeriodUnit)}

																				</span>
																			</div>
																		</div>
																	))}
																	{/* {pkg.PurchaseOptions.sort((a: any, b: any) => a.Price - b.Price) ((priceOption: any) => (
																		<div className="Price" key={priceOption.Id}>
																			{priceOption.PeriodUnit === "None" ? (
																				<div className="Price__group Price__group--wrap-text">
																					<span className="Price__value">
																						{tPriceDescription(
																							priceOption.Description
																						)}
																					</span>
																				</div>
																			) : (
																				<>
																					<div className="Price__group">
																						<span className="Price__value">
																							{priceOption.Price}
																						</span>
																						<span className="Price__unit-per-period">
																							{tCurrency(priceOption.Currency)}/
																							{tPeriodUnit(priceOption.PeriodUnit)}
																						</span>
																					</div>
																					{/* <div className="Price__description">
																						{tPriceDescription(
																							priceOption.Description
																						)}
																					</div> 
																				</>
																			)
																		</div>
																	)) */}

																</div>

																<div className="grid__column">
																</div>
																<button
																	id={pkg.Id}
																	className="PackageInfo__submit PackageInfo__button"
																	// disabled={selectedPackage.packageId ? false : true}
																	onClick={() =>
																		onPackageSelect(pkg.Id, pkg)}
																>
																	{t("packageInfo.butonText")}
																</button>
															</div>
														</h2>

													</div>
												</div>
											</div>
										);
									})}	</div>

							</div>
						)}

					</div>
				</Container>
			</PageWrapper>
			<GetPackagesFailedModal />
		</>
	);
};

export default PackageInfoPage;
