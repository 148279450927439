import Container from "App/components/Container";
import PageWrapper from "App/components/PageWrapper";
import IconCopy from "assets/svg/IconCopy";
import IconError from "assets/svg/IconError";
import IconSuccess from "assets/svg/IconSuccess";
import React, {
	useEffect,
	useCallback,
	useRef,
	useState,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import {
	copyToClipboard,
	getFormattedMsisdn,
	getMappedTariffId,
} from "App/helpers/functions";
import api from "App/api";

import "./style.scss";
import { LoadingSpinnerActionTypes } from "store/loadingSpinner/types";
import { apiEndpoints } from "App/api/endpoints";
import { ResultActionTypes } from "store/result/types";
import Modal from "App/components/Modal";
import { AddressInfoActionTypes } from "store/addressInfo/types";
import { PersonalInfoActionTypes } from "store/personalInfo/types";
import { PackageInfoActionTypes } from "store/packageInfo/types";
import { MsisdnActionTypes } from "store/msisdn/types";
import { Redirect, useLocation } from "react-router";
import routes from "App/routes";

const ResultPage = () => {
	const { t, i18n } = useTranslation();
	const confirmationCodeRef = useRef<HTMLSpanElement>(null);

	const appState: ApplicationState = useSelector(
		(state: ApplicationState) => state,
		shallowEqual
	);

	const [showFillFormFailedModal, setShowFillFormFailedModal] =
		useState(false);

	interface LocationState {
		from: {
			pathname: string;
		};
		hasPrev?: boolean;
		queryCode?: string;
	}
	const location = useLocation<LocationState>();

	const hasPrev = useMemo(() => location.state?.hasPrev || false, [location]);
	var queryCode = useMemo(() => location.state?.queryCode || "", [location]);
	if (queryCode == null || queryCode == "null" || queryCode == "" || queryCode == undefined) {
		console.log("querycode empty ");
		queryCode = "0"
	} else {
		var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?code=' + queryCode;
		window.history.pushState({ path: newurl }, '', newurl);
	}

	const [formData] = useState({
		MSISDN: appState.msisdn.number,
		NAME: appState.personalInfo.nameSurname,
		// IDENTITY_TYPE:
		// 	appState.personalInfo.idType === "1" &&
		// 	appState.personalInfo.idNumber.length === 10
		// 		? "18"
		// 		: appState.personalInfo.idType,
		ID_NO: appState.personalInfo.idNumber,
		// OCCUPATION: appState.personalInfo.occupation,
		EMAIL: appState.personalInfo.email,
		// GENDER: appState.personalInfo.gender,
		// NATIONALITY: appState.personalInfo.nationality,
		CONTACT_NO: appState.personalInfo.contactNumber,
		FATHER_NAME: appState.personalInfo.fathersName,
		// MOTHER_NAME: appState.personalInfo.mothersName,
		// MAIDEN_NAME: appState.personalInfo.mothersMaidenName,
		// BIRTH_PLACE: appState.personalInfo.birthPlace,
		BIRTH_DATE: appState.personalInfo.dob?.split("/").join("."), // DD.MM.YYYY
		POST_PRE: appState.packageInfo.payload === "0" ? "1" : "0", // post_pre -> post 0 pre 1
		PACKAGE_NAME: appState.packageInfo.name,
		TARIFE_ID: getMappedTariffId(appState.packageInfo.packageId), //TODO: WILL BE AVAILABLE FROM SERVICE
		PACK_IDS: getMappedTariffId(appState.packageInfo.packageId), //TODO: WILL BE AVAILABLE FROM SERVICE
		CAMP_ID: appState.packageInfo.campId,
		CITY: appState.addressInfo.city,
		CITY_NAME: appState.addressInfo.cityName,
		DISTRICT: appState.addressInfo.district,
		STREET: appState.addressInfo.street ? appState.addressInfo.street : "-",
		STREET_NAME: appState.addressInfo.streetName
			? appState.addressInfo.streetName
			: "-",
		DEALER_NAME: appState.addressInfo.dealer,
		CHOISE: appState.addressInfo.choise,
		ADDRESS: appState.addressInfo.address
			? appState.addressInfo.address
			: "-",
		TIMEOUT: appState.msisdn.timeout,
		LANG_ID: i18n.language === "tr" ? "1" : "2",
		PROMO_CODE: queryCode,
	});

	//TODO: FOLLOWING TWO LINES WILL BE REQUIRED WHEN PAYLOAD SELECTION IS ACTIVE
	// const [nationality] = useState(appState.personalInfo.nationality);
	// const [packageName] = useState(appState.packageInfo.name);

	const dispatch = useDispatch();

	const copyToValueClipboard = (e: any) => {
		const copyValue = confirmationCodeRef.current?.innerText || "";
		console.log(copyValue);
		if (copyToClipboard(copyValue)) {
			alert(t("resultPage.copyCodeSuccess"));
		} else {
			alert(t("resultPage.copyCodeFail"));
		}
	};

	const resetUserDataOnStore = useCallback(() => {
		// dispatch({
		// 	type: AddressInfoActionTypes.DELETE,
		// });
		// dispatch({
		// 	type: PersonalInfoActionTypes.DELETE,
		// });
		// dispatch({
		// 	type: PackageInfoActionTypes.DELETE,
		// });
		// dispatch({
		// 	type: MsisdnActionTypes.DELETE,
		// });
	}, [dispatch]);

	const callFillForm = useCallback(() => {
		// Exit if already got the confirmation code

		console.log("appState.result fillForm : " + JSON.stringify(appState.result));
		if (
			appState.result?.status !== null &&
			appState.result?.status !== ""
		) {
			return;
		}

		dispatch({
			type: LoadingSpinnerActionTypes.SHOW_LOADING_SPINNER,
		});
		const apiEndpoint = apiEndpoints.fillForm;

		api.post(apiEndpoint, formData)
			.then((response) => {
				const data = response.data;
				resetUserDataOnStore();
				console.log("confirmationCode " + data.CONFIRMATION_CODE);

				if (data.CONFIRMATION_CODE) {
					dispatch({
						type: ResultActionTypes.SAVE,
						payload: {
							msisdn: data.RESERVED_MSISDN,
							confirmationCode: data.CONFIRMATION_CODE,
							status: "SUCCESS",
						},
					});

					return true;
				}

				dispatch({
					type: ResultActionTypes.SAVE,
					payload: {
						status: "FAIL",
					},
				});

				return false;
			})
			.catch((e) => {
				setShowFillFormFailedModal(true);
			})
			.then(() => {
				dispatch({
					type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
				});
			});
	}, [dispatch, resetUserDataOnStore, formData, appState.result]);

	useEffect(() => {
		callFillForm();
	}, [callFillForm]);

	const FillFormFailedModal = () => (
		<Modal show={showFillFormFailedModal}>
			<div className="Modal__content Result__modal">
				<IconError className="Modal__error-icon" />
				<h2 className="Modal__title">
					{t("resultPage.fillFormFailedModalTitle")}
				</h2>
				<p className="Modal__description">
					{t("resultPage.fillFormFailedModalText")}
				</p>
				<button
					type="button"
					className="button Modal__button"
					onClick={() => {
						setShowFillFormFailedModal(false);
						callFillForm();
					}}
				>
					{t("resultPage.tryAgain")}
				</button>
			</div>
		</Modal>
	);

	interface SuccessProps { }
	const Success: React.FC<SuccessProps> = () => {
		return (
			<div className="ResultPage__success">
				<IconSuccess className="ResultPage__success-icon" />
				<h2 className="ResultPage__title">
					{t("resultPage.successTitle")}
				</h2>
				<div className="ResultPage__description">
					<p>{t("resultPage.thanksForChoosingUs")}{appState.result.confirmationCode} {t("resultPage.thanksForChoosingUs2")}</p>
				</div>

				<div className="PersonalInfo__grid">
					<div className="grid">
						<div className="grid__row">
							<div className="grid__column" >
								<div className="PersonalInfo__card">
									<h2 className="PersonalInfo__cardHeader">
										{t("idInfo.subHeader")}
									</h2>
									<div className="grid__row">
										<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.msisdn")}</div>
										<div className="grid__column PersonalInfo__cardLabelLeft" > {formData.MSISDN}</div>
									</div>
									<div className="grid__row">
										<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.selectedPackage")}</div>
										<div className="grid__column PersonalInfo__cardLabelLeft" > {formData.PACKAGE_NAME}</div>
									</div>
										//TODO paket fiaytı eklencek.
									<div className="grid__row">
										<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.deliveryAmount")}</div>
										<div className="grid__column PersonalInfo__cardLabelLeft" >  {t("personalInfo.deliveryAmountAns")}</div>
									</div>
									<div className="grid__row">
										<div className="grid__column PersonalInfo__cardLabel" > </div>
										<div className="grid__column PersonalInfo__cardLabelLeft" ><br></br> </div>
									</div>

									<div className="grid__row">
										<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.labelNameSurname")} </div>
										<div className="grid__column PersonalInfo__cardLabelLeft" > {formData.NAME} </div>
									</div>

									<div className="grid__row">
										<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.labelIdNumber")}</div>
										<div className="grid__column PersonalInfo__cardLabelLeft" > {formData.ID_NO}</div>
									</div>

									<div className="grid__row">
										<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.labelFathersName")}</div>
										<div className="grid__column PersonalInfo__cardLabelLeft" > {formData.FATHER_NAME} </div>
									</div>
									<div className="grid__row">
										<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.labelEmail")}</div>
										<div className="grid__column PersonalInfo__cardLabelLeft" > {formData.EMAIL}  </div>
									</div>
									<div className="grid__row">
										<div className="grid__column PersonalInfo__cardLabel" > {t("personalInfo.labelContactNumber")}</div>
										<div className="grid__column PersonalInfo__cardLabelLeft" > {formData.CONTACT_NO} </div>
									</div>
									{formData.CHOISE === "1" ?
										<div className="grid__row">
											<div className="grid__column PersonalInfo__cardLabel" > {t("addressInfo.labelAddress")}</div>
											<div className="grid__column PersonalInfo__cardLabelLeft" >
												<div>{formData.ADDRESS} {formData.STREET_NAME}
													<div> {formData.DISTRICT}</div>
													<div>{formData.CITY_NAME} </div>
												</div> </div>
										</div>
										: ""}

									<div className="grid__column PersonalInfo__cardLabelLeft" > <br></br></div>
									<div className="grid__row">
										<div className="grid__column PersonalInfo__cardLabel" > {t("idInfo.lblDeliveryType")}</div>
										<div className="grid__column PersonalInfo__cardLabelLeft" > {formData.CHOISE === "1" ? t("idInfo.deliveryTypeH") :
											t("idInfo.deliveryTypeD")} </div>
									</div>
									{formData.CHOISE === "2" ?

										<div className="grid__row">
											<div className="grid__column PersonalInfo__cardLabel" > {t("idInfo.labelDealerName")}</div>
											<div className="grid__column PersonalInfo__cardLabelLeft" >{formData.DEALER_NAME}  </div>
										</div>
										: ""}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <div className="info">
					<div className="info__gsm">
						<span className="info__label">
							{t("resultPage.labelGsm")}:
						</span>
						&nbsp;
						<span className="info__value">
							{getFormattedMsisdn(appState.result.msisdn)}
						</span>
					</div>
					<div className="info__code">
						<span className="info__label">
							{t("resultPage.labelVerificationCode")}:
						</span>
						&nbsp;
						<span className="info__value" ref={confirmationCodeRef}>
							{appState.result.confirmationCode}
						</span>
					</div>
					<button
						className="info__copy-button"
						title={t("resultPage.copyCode")}
						type="button"
						onClick={copyToValueClipboard}
					>
						<IconCopy className="info__copy-icon" />
					</button>
				</div> */}

				{/* <p className="ResultPage__text">
					<Trans i18nKey="resultPage.successText">
						Yukarıdaki işlem onay kodu ile size en yakın{" "}
						<span className="ResultPage__text--blue">
							Turkcell İletişim Merkezine
						</span>{" "}
						gidip hattınızı teslim alabilirsiniz.
					</Trans>
				</p> */}
				{/* {(packageName
					.toLocaleLowerCase(i18n.language)
					.indexOf("gnç") !== -1 ||
					packageName
						.toLocaleLowerCase(i18n.language)
						.indexOf("gnc") !== -1) && (
					<p className="ResultPage__text">
						{t("resultPage.gncStudentDocumentsText")}
					</p>
				)}
				{nationality !== "245" && (
					<p className="ResultPage__text">
						{t("resultPage.residenceOrWorkPermitText")}
					</p>
				)} */}
			</div>
		);
	};
	interface LocationState {
		from: {
			pathname: string;
		};
		hasPrev?: boolean;
		queryCode?: string;
	}
	interface FailProps { }
	const Fail: React.FC<FailProps> = () => {
		return (
			<div className="ResultPage__fail">
				<IconError className="ResultPage__fail-icon" />
				<h2 className="ResultPage__title">
					{t("resultPage.failTitle")}
				</h2>
				<p className="ResultPage__text">{t("resultPage.failText")}</p>
			</div>
		);
	};

	if (hasPrev !== true) {
		return <Redirect to={routes.root} />;
	}

	return (
		<>
			<PageWrapper>
				<Container className="Container--align-contents-middle Container--narrow">
					{appState.result.status === "SUCCESS" ? (
						<Success />
					) : appState.result.status === "FAIL" ? (
						<Fail />
					) : (
						<div style={{ height: "50vh" }}></div>
					)}
				</Container>
			</PageWrapper>
			<FillFormFailedModal />
		</>
	);
};

export default ResultPage;
