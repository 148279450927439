import Container from "App/components/Container";
import PageWrapper from "App/components/PageWrapper";
import RadioButton from "App/components/RadioButton";
import routes from "App/routes";
import api from "App/api";
import leftImage from "assets/images/fikriye.png";
import rightImage from "assets/images/gozluklu.png";


import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat from "react-number-format";
import { useHistory, useLocation } from "react-router-dom";
import "./style.scss";
import { apiEndpoints } from "App/api/endpoints";
import { getRecaptchaScore } from "App/components/ReCaptchaV3";
import Modal from "App/components/Modal";
import IconError from "assets/svg/IconError";
import { useDispatch } from "react-redux";
import { LoadingSpinnerActionTypes } from "store/loadingSpinner/types";
import { MsisdnActionTypes } from "store/msisdn/types";
import ProcessBar from "App/components/ProcessBar";
import ReCAPTCHA from "react-google-recaptcha";

interface Props {
	type?: string;
}
const SearchPhoneNumberPage: React.FC<Props> = ({ type }) => {

	const reRef = useRef<ReCAPTCHA>(null);

	const { t } = useTranslation();
	const [selected, setSelected] = useState("");
	const [lastFour, setLastFour] = useState("");
	const [showErrorModal, setShowErrorModal] = React.useState(false);
	const [errorModalMessage, setErrorModalMessage] =
		React.useState<string>("");
	const history = useHistory();

	// var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?code=1';
	// window.history.pushState({path:newurl},'',newurl);

	function useQuery() {
		const { search } = useLocation();

		return React.useMemo(() => new URLSearchParams(search), [search]);
	}
	const [isCaptchaSuccessful, setIsCaptchaSuccess] = React.useState(false)

	let query = useQuery();
	console.log("query ", query.get("code"))
	const queryCode = query.get("code")

	const dispatch = useDispatch();

	const handleOnClick = async () => {
		dispatch({
			type: LoadingSpinnerActionTypes.SHOW_LOADING_SPINNER,
		});

		//22nısan kapattık bu ıkı satırı
		//const token = await reRef.current?.executeAsync();
		//reRef.current?.reset();


		const apiEndpoint = apiEndpoints.getAvailableMsisdn;

		// if (
		// 	!(window as any).grecaptcha ||
		// 	(await getRecaptchaScore(
		// 		process.env.REACT_APP_GRECAPTCHA_SITE_KEY
		// 	)) < 0.5
		// ) {
		// 	dispatch({
		// 		type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
		// 	});
		// 	setErrorModalMessage(t("validation.recaptchaErrorMessage"));
		// 	setShowErrorModal(true);
		// 	return;
		// }

		api.post(apiEndpoint, {
			value: selected,
			param: lastFour,
		})
			.then((response) => {
				const data = response.data;
				setIsCaptchaSuccess(false)

				dispatch({
					type: MsisdnActionTypes.SAVE,
					payload: {
						timeout: data.TIMEOUT,
					},
				});

				var newurl = routes.choosePhoneNumber;
				history.push({
					pathname: newurl,
					state: {
						phoneNumbers: data.MSISDN_LIST,
						queryCode: queryCode
					},
				});
			})
			.catch((e) => {
				console.log(e);
				setErrorModalMessage(
					t("searchNumber.errorFetchingNumbersTryAgain")
				);
				setShowErrorModal(true);
			})
			.then(() => {
				dispatch({
					type: LoadingSpinnerActionTypes.HIDE_LOADING_SPINNER,
				});
			});
	};
	const onChange = async (val:any) => {
		setIsCaptchaSuccess(!isCaptchaSuccessful)
		//console.log("captcha value: ", val);
	  }
	const ErrorModal = () => (
		<Modal show={showErrorModal}>
			<div className="Modal__content PersonalInfo__modal">
				<IconError className="Modal__error-icon" />
				<h2 className="Modal__title">{t("modal.failTitle")}</h2>
				<p className="Modal__description">{errorModalMessage}</p>
				<button
					type="button"
					className="button Modal__button"
					onClick={() => {
						setShowErrorModal(false);
					}}
				>
					{t("modal.OK")}
				</button>
			</div>
		</Modal>
	);

	return (
		<>
			<PageWrapper className="interior">
				<div className="grid">
					<div className="grid__rowProcess">
						<div className="grid__column grid__columnWitoutPadding">
							<ProcessBar />
						</div>
					</div>
				</div>
				<Container className="Container--align-contents-middle">
					<div className="grid">
						<div className="grid__rowU">
							<div className="grid__columnU grid__alignStart">

								<img
									src={leftImage}
								/>

							</div>

							<div className="grid__columnU4">

								<h2 className="NumberSelection__heading heading--center">
									{t("searchNumber.title")}
									{type}
								</h2>
								<h2 className="NumberSelection__subHeading heading--center">
									{t("searchNumber.subTitle")}
									{type}
								</h2>

								<div className="NumberSelection__card">
									<div className="NumberSelection__text">
										<p>{t("searchNumber.text")}</p>
									</div>
									<div className="NumberSelection__radio-group">
										<RadioButton
											value="2"
											selected={selected}
											onChange={setSelected}
											className="NumberSelection__radio"
										>
											<div>{t("searchNumber.labelLastFour")}</div>

										</RadioButton>
										<div className="NumberSelection__form-input-container">
											<NumberFormat
												label="asd"
												format="0 (5XX) XXX ## ##"
												allowEmptyFormatting
												mask="_"
												className="form__input NumberSelection__form-input"
												value={lastFour}
												onValueChange={(values) => {
													const { value } = values;
													setLastFour(value);
												}}
											/>
										</div>
										<RadioButton
											value="1"
											selected={selected}
											onChange={setSelected}
											className="NumberSelection__radio"
										>
											<div>{t("searchNumber.labelRandom")}</div>
										</RadioButton>
									</div>
							
									<div className="NumberSelection__buttons">
										<button
											type="button"
											className="button"
											disabled={
												(!isCaptchaSuccessful) ||
												(selected
													? selected === "2"
														? lastFour.length < 4
															? true
															: false
														: false
													: true)
											}
											onClick={handleOnClick}
										>
											{t("searchNumber.buttonSearch")}
										</button>
									</div>

									<div className="NumberSelection__buttons"> 
									<ReCAPTCHA
											sitekey={`${process.env.REACT_APP_V2_RECAPTCHA_SITE_KEY}`}
											onChange={onChange}
											ref={reRef}

											/>	

								</div>
								</div>
							</div>
							<div className="grid__columnU6 grid__alignEnd">
								<img
									src={rightImage}
								/>
							</div>
						</div>
					</div>
				</Container>
			</PageWrapper>
			<ErrorModal />
		</>
	);
};

export default SearchPhoneNumberPage;
